import { createWebHistory, createRouter } from "vue-router";

import store from '@/state/store'
import routes from './routes'
import axios from "axios";

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((routeTo, routeFrom, next) => {
  if (import.meta.env.VITE_SOME_KEY == "authapi") {
    // Check if auth is required on this route
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
    if (!authRequired) return next()
    else {
      if (store.getters["auth/loggedIn"]) {
        // Validate the local user token...
        return next()
      }
      // return next()
      // redirectToLogin()
      // function redirectToLogin() {
      //   next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
      // }
    }
    // If auth is required and the user is logged in...

  } else {
    const publicPages = ['/login', '/register', '/forgot-password'];
    const authpage = !publicPages.includes(routeTo.path);
    const loggeduser = localStorage.getItem('user');
    if (authpage && !loggeduser) {
      return next('/login');
    }
    next();
  }
})
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status == 401) {
    store.commit('auth/REMOVE_CURRENT_USER', null);
    router.push({ name: 'login' })
  }
})

// Before each route evaluates...

export default router
