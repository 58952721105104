import axios from "axios";
export const profileService = {
    getAllBids: (reqData) => axios.get(`${import.meta.env.VITE_API_URL}/api/bids_get`, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    purchaseBid: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/purchase_bid`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    getmyProfile: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/get_profile`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    upadteProfile: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/edit_profile`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    changePassword: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/change_password`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
}