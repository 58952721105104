import axios from "axios";
export const BidProduct = {
    // <--  for not logged in user -- >
    getProductforNoAuth: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/noauth_product_list`, reqData),
    getProductDetailforNoAuth: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/noauth_product_detail`, reqData),
    getBidDiscounts: (reqData) => axios.get(`${import.meta.env.VITE_API_URL}/api/discount_get`, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    // <--  for not logged in user-- >

    listProduct: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/product_list`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    viewProductdetail: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/get_product_detail`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    placeProductBid: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/place_bid`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    purchaseProduct: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/purchase_product`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    getMyBids: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/get_my_bids`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
    addBidDiscount: (reqData) => axios.post(`${import.meta.env.VITE_API_URL}/api/add_discount`, reqData, {
        headers: { 'Authorization': 'Bearer ' + reqData.token }
    }),
}