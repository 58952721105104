import { profileService } from '@/services/profile/profile.service';
import router from '../../router/index';
import { useToast } from "vue-toastification";
var toast = useToast()
const state = {
    bid_list: [],
}
const mutations = {
    SET_BID_LIST(state, LoggedUser) {
        state.bid_list = LoggedUser;
    },
    SET_CURRENT_USER(state, LoggedUser) {
        localStorage.setItem("user", JSON.stringify(LoggedUser));
    },
}
const getters = {
    // Whether the user is currently logged in.
    // loggedIn: (state) => state.currentUser,
}

const actions = {
    getAllBids({ commit }, reqData) {
        return profileService.getAllBids(reqData).then((response) => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
            } else {
                commit('SET_BID_LIST', response.data.result)
                // toast.success(response.data.message);
                return
            }
        })
    },
    purchaseBid({ commit }, reqData) {
        return profileService.purchaseBid(reqData).then((response) => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
            } else {
                console.log(response.data);
                var payment_link = response.data.result.payment_page
                window.location.href = payment_link;
                // toast.success(response.data.message);
                return
            }
        })
    },
    getmyProfile({ commit }, reqData) {
        return profileService.getmyProfile(reqData).then((response) => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
            } else {
                localStorage.setItem("user", JSON.stringify(response.data.result));
                // toast.success(response.data.message);
                return response.data.result
            }
        })
    },
    upadteProfile({ commit }, reqData) {
        return profileService.upadteProfile(reqData).then((response) => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
            } else {
                toast.success(response.data.message);
                commit('SET_CURRENT_USER', response.data.result)
                router.push({ name: 'my-profile' })
                return response.data.result
            }
        })
    },
    changePassword({ commit }, reqData) {
        return profileService.changePassword(reqData).then((response) => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
            } else {
                toast.success(response.data.message);
                router.push({ name: 'my-profile' })
                return response.data.result
            }
        })
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
