import { authService } from '@/services/auth/auth.service';
import router from '../../router/index';
import { useToast } from "vue-toastification";
var toast = useToast()
const state = {
    currentUser: localStorage.getItem('user'),
}
const mutations = {
    SET_CURRENT_USER(state, LoggedUser) {
        state.currentUser = LoggedUser;
        localStorage.setItem("user", JSON.stringify(LoggedUser));
    },
    REMOVE_CURRENT_USER(state, LoggedUser) {
        state.currentUser = LoggedUser;
        localStorage.removeItem("user");
        localStorage.removeItem("visitedUserIds");
    },
}
const getters = {
    // Whether the user is currently logged in.
    loggedIn: (state) => state.currentUser,
}

const actions = {
    register({ commit }, reqData) {
        // commit('SET_CURRENT_USER', null)
        return authService.register(reqData).then((response) => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
                return response.data.message
            } else {
                toast.success(response.data.message, { timeout: 2000 });
                router.push({ name: 'login' })
            }
        })
    },
    login({ commit }, reqData) {
        return authService.login(reqData).then(response => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
                return response.data.message
            } else {
                toast.success(response.data.message);
                router.push({ name: "default" })
                commit('SET_CURRENT_USER', response.data.result)
            }
        });
    },
    resetPassword({ commit }, reqData) {
        return authService.resetPassword(reqData).then((response) => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
                return response.data.message
            } else {
                toast.success(response.data.message);
                router.push({ name: "login" })
                const user = response.data.message
                return user
            }
        });
    },
    forgotPassword({ commit }, reqData) {
        return authService.forgotPassword(reqData).then((response) => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
                return response.data.message
            } else {
                toast.success(response.data.message);
                router.push({ name: 'login' });
                return response.data.message
            }
        });
    },
    emailVerify({ commit }, reqData) {
        return authService.emailVerify(reqData).then((response) => {
            if (response.data.code == 101) {
                const user = response.data.message
                return user
            } else {
                toast.success(response.data.message);
                const user = response.data.message
                commit('EXTRA_COMMIT', user)
                return user
            }
        });
    },
    logoutuser({ commit }, reqData) {
        return authService.logoutUser(reqData).then(response => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
            } else {
                router.push({ name: "login" });
                toast.success(response.data.message);
                commit('REMOVE_CURRENT_USER', null)
            }
        });
    },

}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
