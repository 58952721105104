<template>
  <footer class="text-center text-light">
    <!-- Copyright -->
    <div class="text-center p-3" style="background-color: #1D1D1D">
      © 2023 Copyright:
      <a class="text-light" href="https://lxbids.com/">lxbids.com</a>
    </div>
    <!-- Copyright -->
  </footer>
</template>
