// import './assets/main.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./state/store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import BootstrapVue3 from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/js/solid';
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Skeletor } from 'vue-skeletor';
import 'vue-skeletor/dist/vue-skeletor.css';
import "../src/assets/scss/main.css";
// import authPlugin from './authPlugin';
createApp(App)
    .use(router)
    .use(store)
    .use(Toast, {
        transition: "Vue-Toastification__fade",
        hideProgressBar: true,
        timeout: 1500,
    })
    // .use(authPlugin)
    .use(VueSweetalert2)
    .use(LoadingPlugin)
    .component(Skeletor.name, Skeletor)
    .use(BootstrapVue3)
    .mount('#app')
