import axios from "axios";
export const authService = {
    login: (formData) => axios.post(`${import.meta.env.VITE_API_URL}/api/login`, formData),

    user: () => axios.post(`${import.meta.env.VITE_API_URL}/api/user`),

    register: (formData) => axios.post(`${import.meta.env.VITE_API_URL}/api/register`, formData),

    resetPassword: (formData) => axios.post(`${import.meta.env.VITE_API_URL}/api/forgot_password`, formData),

    forgotPassword: (formData) => axios.post(`${import.meta.env.VITE_API_URL}/api/reset_Password`, formData),

    logoutUser: (formData) => axios.post(`${import.meta.env.VITE_API_URL}/api/logout`, formData, {
        headers: { 'Authorization': 'Bearer ' + formData.token }
    }),
    emailVerify: (formData) => axios.post(`${import.meta.env.VITE_API_URL}/api/email_verify`, formData, {
        headers: { 'Authorization': 'Bearer ' }
    }),

}