
import Vuex from 'vuex';
import modules from './modules/index'
import * as Vue from "vue";
const app = Vue.createApp();

app.use(Vuex)
const store = new Vuex.Store({
    modules: modules
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    // strict: import.meta.env.NODE_ENV !== 'production',
})
// console.log("store", store.state.auth.authloggedIn);
export default store

