import { Payment } from '@/services/payment/payment.service';
// import { saveState } from '../../helpers/authservice/auth-header'
import { useToast } from "vue-toastification";
import router from '../../router';
var toast = useToast()
const state = {
    currentUser: localStorage.getItem('auth.currentUser'),

}
const mutations = {
    NOT() {
        console.log('')
    }
}
const getters = {
    // Whether the user is currently logged in.
    // loggedIn: (state) => state.currentUser,
}
const actions = {
    processPayment({ commit }, reqData) {
        return Payment.processBidPayment(reqData).then(response => {
            if (response.data.code == 101) {
                router.push({ name: "cancel" })
                // toast.error(response.data.message)
                setTimeout(() => {
                    router.push(import.meta.env.VITE_PURCHASE_BID)
                }, 1000);
            } else {
                // toast.success(response.data.message)
                setTimeout(() => {
                    router.push(import.meta.env.VITE_PURCHASE_BID)
                }, 1000);
            }
        }).catch((response) => {
            console.log("error");
            console.log(response);
        });
    },
    advancePayment({ commit }, reqData) {
        return Payment.advanceBidPayment(reqData).then(response => {
            if (response.data.code == 101) {
                // toast.error(response.data.message)
                router.push({ name: "cancel" })
                setTimeout(() => {
                    var productId = btoa(`${response.data.result.product_id}`)
                    router.push(`${import.meta.env.VITE_PRODUCT_DETAIL_URL}${productId}`)
                }, 1000);
            } else {
                // toast.success(response.data.message)
                var bid_id = btoa(`${response.data.result.bid_id}`)
                var product_id = btoa(`${response.data.result.product_id}`)
                setTimeout(() => {
                    router.push(`${import.meta.env.VITE_PRODUCT_DISCOUNT_URL}${bid_id}&${product_id}`)
                }, 1000);
            }
        }).catch((response) => {
            console.log("error");
            console.log(response);
        });
    },
    PurchaseProduct({ commit }, reqData) {
        return Payment.PurchaseProduct(reqData).then(response => {
            var productId = btoa(`${response.data.result.product_id}`)
            if (response.data.code == 101) {
                // toast.error(response.data.message)
                router.push({ name: "cancel" })
                setTimeout(() => {
                    router.push(`${import.meta.env.VITE_PRODUCT_DETAIL_URL}${productId}`)
                }, 1000);
            } else {
                // toast.success(response.data.message)
                setTimeout(() => {
                    router.push(`${import.meta.env.VITE_PRODUCT_DETAIL_URL}${productId}`)
                }, 1000);
            }
        }).catch((response) => {
            console.log("error");
            console.log(response);
        });
    },
    // getPaymentData({ commit }, reqData) {
    //     return Payment.getPaymentDetail(reqData).then(response => {
    //         if (response.data.code == 101) {
    // toast.error(response.data.message)
    //         } else {
    //             const details = response.data.result
    //             return details
    //         }
    //     }).catch((response) => {
    //         console.log("error");
    //         console.log(response);
    //     });
    // },

}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};