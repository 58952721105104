import { BidProduct } from '@/services/products/product.service';
import router from '../../router/index';
import { useToast } from "vue-toastification";
var toast = useToast()
const state = {
    product_list: [],
    product_detail: [],
    state_auction: "live",
    mybids_auction: "live"
}

const mutations = {
    Get_Product_List(state, product) {
        state.product_list = product
    },
    Get_Product_Detail(state, productDetail) {
        state.product_detail = productDetail
    },
    SET_AUCTION(state, newValue) {
        state.state_auction = newValue;
    },
    SET_MYBIDS_AUCTION(state, newValue) {
        state.mybids_auction = newValue;
    },
    NOT() {
        console.log('heyyy')
    }
}
const getters = {

}
const actions = {
    getProductforNoAuth({ commit }, reqData) {
        return BidProduct.getProductforNoAuth(reqData).then(response => {
            if (response.data.code == 101) {
                const product = response.data
                commit('Get_Product_List', [])
                return product
            } else {
                const product = response.data
                commit('Get_Product_List', product)
                return product
            }
        }).catch((response) => {
            console.log(response);
        });
    },
    getProductDetailforNoAuth({ commit }, reqData) {
        return BidProduct.getProductDetailforNoAuth(reqData).then(response => {
            if (response.data.code == 101) {
                commit('Get_Product_Detail', '')
                toast.error(response.data.message);
            } else {
                const productDetail = response.data.result
                commit('Get_Product_Detail', productDetail)
                return productDetail
            }
        }).catch((response) => {
            console.log(response);
        });
    },

    getProductList({ commit }, reqData) {
        return BidProduct.listProduct(reqData).then(response => {
            if (response.data.code == 101) {
                const product = response.data
                commit('Get_Product_List', [])
                return product
            } else {
                const product = response.data
                commit('Get_Product_List', product)
                return product
            }
        }).catch((response) => {
            console.log(response);
        });
    },
    viewProductdetail({ commit }, reqData) {
        return BidProduct.viewProductdetail(reqData).then(response => {
            if (response.data.code == 101) {
                commit('Get_Product_Detail', '')
                toast.error(response.data.message);
            } else {
                const productDetail = response.data.result
                commit('Get_Product_Detail', productDetail)
                return productDetail
            }
        }).catch((response) => {

            console.log(response);
        });
    },
    getMyBids({ commit }, reqData) {
        return BidProduct.getMyBids(reqData).then(response => {
            if (response.data.code == 101) {
                const product = response.data
                commit('Get_Product_List', [])
                return product
            } else {
                const product = response.data
                commit('Get_Product_List', product)
                return product
            }
        }).catch((response) => {
            console.log(response);
        });
    },
    placeProductBid({ commit }, reqData) {
        return BidProduct.placeProductBid(reqData).then(response => {
            if (response.data.code == 101) {
                // commit('Get_Product_List', '')
                toast.error(response.data.message);
            } else {
                const data = response.data.result
                if (data.payment_page != '' && data.payment_page != undefined) {
                    window.location.href = data.payment_page
                }
                else {
                    const productDiscountURL = `${import.meta.env.VITE_PRODUCT_DISCOUNT_URL}${btoa(data.bid_id)}&${btoa(data.product_id)}`
                    router.push(productDiscountURL)
                }
                return data
            }
        }).catch((response) => {
            console.log(response);
        });
    },
    purchaseProduct({ commit }, reqData) {
        return BidProduct.purchaseProduct(reqData).then(response => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
            } else {
                const data = response.data.result
                window.location.href = response.data.result.payment_page
                return data
            }
        }).catch((response) => {
            console.log(response);
        });
    },
    addBidDiscount({ commit }, reqData) {
        return BidProduct.addBidDiscount(reqData).then(response => {
            if (response.data.code == 101) {
                toast.error(response.data.message);
                const data = response.data.result
                setTimeout(() => {
                    const productDetailURL = `${import.meta.env.VITE_PRODUCT_DETAIL_URL}${btoa(data.product_id)}`
                    router.push(productDetailURL)
                }, 1000);
            } else {
                toast.success(response.data.message);
                const data = response.data.result
                setTimeout(() => {
                    const productDetailURL = `${import.meta.env.VITE_PRODUCT_DETAIL_URL}${btoa(data.product_id)}`
                    router.push(productDetailURL)
                }, 3000);
                return data
            }
        }).catch((response) => {
            console.log(response);
        });
    },
    getBidDiscounts({ commit }, reqData) {
        return BidProduct.getBidDiscounts(reqData).then(response => {
            if (response.data.code == 101) {
                const discount = response.data.result
                // commit('Get_Product_List', [])
                return discount
            } else {
                const discount = response.data.result
                // commit('Get_Product_List', product)
                return discount
            }
        }).catch((response) => {
            console.log(response);
        });
    },


}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};