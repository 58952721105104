import store from "@/state/store";

export default [
  // <!-------- Auth Routers --------!>
  {
    path: "/register",
    name: "register",
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/loggedIn"]) {
        next({ name: "default" });
      } else {
        next()
      }
    },
    component: () => import("./views/auth/register.vue"),
  },
  {
    path: "/login",
    name: "login",
    beforeEnter: (to, from, next) => {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "default" });
      } else {
        // Continue to the login page
        next()
      }
    },
    component: () => import("./views/auth/login.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/auth/forgot-password.vue"),
    meta: {
      beforeEnter(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "default" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/reset-password/:id/:token",
    name: "reset-password",
    props: true,
    component: () => import("./views/auth/reset-password.vue"),
  },
  // <!-------- Auth Routers End --------!>
  {
    path: "/",
    name: "default",
    // meta: {
    //   authRequired: true,
    // },
    component: () => import("./views/product/product-list.vue"),
  },
  // <!-------- Product Routers Start --------!>
  {
    path: "/product-detail/:id",
    name: "product-detail",
    // meta: {
    //   authRequired: true,
    // },
    component: () => import("./views/product/product-detail.vue"),
  },
  {
    path: "/purchase-bid",
    name: "purchase-bid",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/profile/purchase-bid.vue"),
  },
  {
    path: "/discount/:bid_id&:product_id",
    name: "discount",
    props: true,
    beforeEnter(to, from, next) {
      const visitedUserIds =
        JSON.parse(localStorage.getItem("visitedUserIds")) || [];
      const bid_id = to.params.bid_id;
      if (visitedUserIds.includes(bid_id)) {
        next({ path: `/product-detail/${to.params.product_id}` });
      } else {
        visitedUserIds.push(bid_id);
        localStorage.setItem("visitedUserIds", JSON.stringify(visitedUserIds));
        next();
      }
    },
    meta: {
      authRequired: true,
    },
    component: () => import("../components/discount.vue"),
  },
  // <!-------- Product Routers End --------!>
  // <!-------- Payment Routers Start --------!>
  {
    path: "/success/:sessionID&:userData",
    name: "success",
    props: true,
    component: () => import("./views/payment/success.vue"),
  },
  {
    path: "/success1/:sessionID&:userData",
    name: "success1",
    props: true,
    component: () => import("./views/payment/success1.vue"),
  },
  {
    path: "/success2/:sessionID&:userData",
    name: "success2",
    props: true,
    component: () => import("./views/payment/success2.vue"),
  },
  {
    path: "/cancel",
    name: "cancel",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/payment/cancel.vue"),
  },
  // <!-------- Payment Routers End --------!>
  // <!-------- Profile Routers Start --------!>
  {
    path: "/profile",
    name: "my-profile",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/profile/my-profile.vue"),
  },
  {
    path: "/edit-profile",
    name: "edit-profile",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/profile/edit-profile.vue"),
  },
  {
    path: "/change-password",
    name: "change-password",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/profile/change-password.vue"),
  },
  // <!-------- Profile Routers End --------!>
  {
    path: "/my-bids",
    name: "my-bids",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/profile/my-bids.vue"),
  },
  // <!-------- Settings Routers Start --------!>
  {
    path: "/settings",
    name: "settings",
    component: () => import("./views/settings/settings.vue"),
  },
  {
    path: "/terms-condition",
    name: "terms-condition",
    component: () => import("./views/settings/terms-condition.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("./views/settings/privacy-policy.vue"),
  },
  {
    path: "/how-it-works",
    name: "how-it-works",
    component: () => import("./views/settings/how-it-works.vue"),
  },
  // <!-------- Settings Routers Ends --------!>

];