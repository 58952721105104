import { settingServices } from '@/services/settings/settings.service';

const actions = {
    getSettings({ commit }, reqData) {
        return settingServices.getSettings(reqData).then(response => {
            if (response.data.code == 101) {
                const settings = response.data.result
                return settings
            } else {
                const settings = response.data.result
                return settings
            }
        }).catch((response) => {
            console.log(response);
        });
    },
}
export default {
    namespaced: true,
    // state,
    // mutations,
    // getters,
    actions
};
