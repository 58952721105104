import auth from './auth'
import product from './product'
import profile from './profile'
import payment from './payment'
import settings from './settings'
const modules = {
    auth,
    product,
    profile,
    payment,
    settings
}
export default modules